import React from 'react'
import { Link, StaticQuery, graphql} from 'gatsby'
import styled from 'styled-components'
import basement from '../images/basement-hallway-stairs.jpg'

const ServiceContainer = styled.div`
    padding: 80px 60px;
    background-color:#fafafa ;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    h1{
      font-size: 4.5rem;
      margin-bottom: 0;
    }
    h2{
      font-weight: 300;
      font-family: 'ArmyRust';
      font-size: 2rem;
      color: #CB8C00;
    }
    p{
      font-family: 'Mukta', sans-serif;
      .5rem;
    }
    .services{
      color: #403E3E;
    }
  
`
const ServiceArticle = styled.article`
    padding: 40px 40px;
    display:flex; 
    flex-direction: row ;
    justify-content: space-between;
    img{

      width: 300px;
      height: 300px;
    }
    div{
      h2{
        font-family: 'ArmyRust';
        font-size: 3rem;
        color: #403E3E;
      }
      padding-left: 100px;
      flex-direction: column;
    }
    @media only screen and (max-width: 56.25em) {
      padding: 40px 40px;
      display:flex; 
      flex-direction:column ;
      justify-content: space-between;
      img{
  
        width: 300px;
        height: 300px;
      }
      div{
        h2{
          font-family: 'ArmyRust';
          font-size: 3rem;
          color: #403E3E;
        }
        padding-left: 0px;
        flex-direction: column;
      }
    }
    @media only screen and (max-width: 37.5em) {
      padding: 40px 40px;
      display:flex; 
      flex-direction: column;
      justify-content: space-between;
      img{

        width: 300px;
        height: 300px;
      }
      div{
        text-align:center;
        h2{
          font-family: 'ArmyRust';
          font-size: 3rem;
          color: #403E3E;
        }
        padding-left: 0px;
        flex-direction: column;
      }
    }
`
const SERVICE_QUERY = graphql`
  query {
    file(relativePath: {regex: "/bg/"}) {
      size
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allMarkdownRemark( 
      filter: {fileAbsolutePath: {regex: "/(services)/"}}) {
      edges {
        node {
          excerpt(pruneLength: 1000)
          id
          frontmatter {
            title
            description
          }
        }
      }
    }
  }   
`     

const Service = () => (
  <StaticQuery
    query={SERVICE_QUERY}
    render = {({allMarkdownRemark}) => (
      <ServiceContainer>
        <h1>Gigliotti Brothers</h1>
        <h1 className ="services">Services</h1>
        <p>GB Contracting is a family owned business that is ready to create</p>
        { allMarkdownRemark.edges.map(edge=> (
            <ServiceArticle key= {edge.node.id}>
              <img src= {basement} alt="basement"></img>
              <div>
                <h2>{edge.node.frontmatter.title}</h2>
                <p>{edge.node.frontmatter.description}</p>
              </div>
            </ServiceArticle>
          ))
        }
        <Link to="/services"><h2>Learn More</h2></Link>
        </ServiceContainer>
    )}
  >
    
  </StaticQuery>
    
)

export default Service;
