import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import Image from 'gatsby-image'



const INSTAGRAM_QUERY = graphql`
query {
    allInstagramContent(limit:8) {
        edges {
            node {
                id
                link
                caption{
                    text
                }
                localImage{
                    childImageSharp {
                        fluid(maxHeight: 500, maxWidth: 500 quality: 50) {
                            ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        }
                    }
                }
                images {
                    standard_resolution {
                    width
                    height
                    url
                    }
                    low_resolution{
                        url
                    }
                }
            }
        }
    }
}
`
const Header = styled.div `
    display: flex;
    margin-bottom: 2rem;
    justify-content: center;
    align-content:center;
    a{
        padding-bottom: 20px;
        font-family: 'ArmyRust';
        font-size: 4rem;
        font-weight: 300;
        color: #CB8C00;
        
    }
    a,
    a > span {
        position: relative;
        text-decoration: none;
        line-height: 4rem;
        color: #CB8C00;
        
    }

    a:before, a:after,
    a > span:before,
    a > span:after {
        content: "";
        position:absolute;
        transition: transform 0.5s ease;
    }

    a:before {
        left: 18%;
        bottom: 0;
        width: 70%;
        height: .7rem;
        background: #403E3E;
        border-radius: 100px;
        transform: scaleX(.5);
    }
    a:hover:before {
        transform: scaleX(1);
    }
    @media only screen and (max-width: 56.25em) {
        a{
            font-size: 2.5rem;
        }}
    @media only screen and (max-width: 37.5em) {
        a{
            padding-bottom: 20px;
            font-size: 2rem;
        }}
`
const Container = styled.div`
    display:grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
`

const Instagram =() => (
    <StaticQuery 
        query= {INSTAGRAM_QUERY}
        render = {({allInstagramContent}) => (
            <div style={{ margin: `0 auto`,
                    maxWidth: `90vw`,
                    padding: `80px 40px`}}>
                <Header>
                    <a href="https://www.instagram.com/gigliottibrothers/?hl=en"><span>@gigliottibrothers</span></a>
                </Header>
                <Container>
                {
                    allInstagramContent.edges.map((item) => {
                        let captionText = item.node.caption? deleteTags(item.node.caption.text) : "Instagram Post"
                        return(
                            item.node.localImage? <Image key={item.node.id} fluid={item.node.localImage.childImageSharp.fluid} caption={captionText}></Image> : <div key={item.node.id}></div>
                        )
                    })
                }
                </Container>
            </div>
        )
        }
    >
    </StaticQuery>
    

);

const deleteTags = (text)=> {
    return  text.replace(/^(\s*#\w+\s*)+$/gm, "")
}


export default Instagram;