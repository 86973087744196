import React from 'react'
// import { Link } from 'gatsby'
// import styled from 'styled-components'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Instagram from '../components/instagram'
import ContactForm from '../components/contactForm'
import Service from '../components/service'
import Quote from '../components/quote'
// import Testimonials from '../components/testimonials'
import './index.css'

const IndexPage = () => (
  <Layout>
      <SEO title="Home" keywords={[`gatsby5`, `application`, `react`]} />
      <ContactForm/>
      <Service/>
      <Instagram/>
      {/* <Testimonials/> */}
      <Quote/>
  </Layout>
)

export default IndexPage
